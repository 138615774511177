import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import {  browserWindow } from "util/localStorageHelper";
import { Helmet } from "react-helmet";
import Button from 'components/CustomButtons/Button.jsx';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Accordion from "components/Accordion/Accordion.jsx";
// const STRHOSPITALCOUNT = process.env.STRHOSPITALCOUNT;


const faq = [
    {
        title:'How do I get this policy?',
        description:<h5>To buy this policy, visit www.starhealth.in or visit your nearest Star Health branch. You can also call the Contact number  044 6900 6900 / 044 4674 5800 for more details on the plan.</h5>,
        content:false
    },
    {
        title:'Why should I buy a policy for my parents?',
        description:<h5>Having health insurance for parents is highly essential and should be prioritised. Despite the rising health care costs in India, a health insurance plan can help your parents to be prepared for any medical contingencies, ensuring timely care and quality treatment.</h5>,
        content:false
    },
    {
        title:'Do health insurance policies need a pre-insurance medical screening?',
        description:<h5>Pre-insurance medical screening requirement depends on what policy you choose.</h5>,
        content:false
    },
    {
        title:'Can I cover my parents in an existing health insurance policy?',
        description:<h5>Yes. Having multiple policies is always an advantage when the medical bill exceeds the sum insured limit or when your sum insured is exhausted. Such plans serve as indemnity-based health insurance plans.</h5>,
        content:false
    },
    {
        title:'What is the age limit of Senior Citizens Red Carpet Health Insurance Policy?',
        description:<h5>Senior citizens aged between 60 - 75 years can take this insurance.</h5>,
        content:false
    },
    {
        title:'How do I choose a health insurance with the best coverage?',
        description:<h5>Choosing a health insurance depends on an individual needs. However, make sure to choose a health insurance policy that has a comprehensive coverage. A comprehensive cover includes coverage for alternate medicines, allopathic treatments, modern treatments, non-payables, in patient/out-patient expenses, annual health checkups and many more.</h5>,
        content:false
    },
    {
        title:'Can an 80-year-old get health insurance?',
        description:<h5>The Senior Citizens Red Carpet health insurance plan enrolls people between ages 60 - 75 years as of now. Further, renewal is possible even after 75 till they are alive. We are likely to introduce many more policies to suit your requirements. Stay tuned with us.</h5>,
        content:false
    },
    {
        title:'Will my health insurance cover my medical expenses if I get admitted in a non-network hospital?',
        description:<h5>Yes, your health insurance policy reimburses the treatment expenses incurred by you at a non-network hospital, provided the claim is admissible as per the conditions mentioned in the policy.</h5>,
        content:false
    },
    {
        title:'Is it mandatory to undergo pre-insurance medical screening before purchasing a health insurance policy?',
        description:<h5>Pre-insurance medical screening helps with the declaration of any pre-existing disease and its severity. Pre-existing diseases plays a major role in health insurance policy. It is compulsory to disclose your health condition related to the pre-existing disease (PED), before purchasing a health insurance policy. A pre-insurance medical screening would be called for by the medical underwriting team.</h5>,
        content:false
    },
]

const ParentsPageSEO = ({ classes }) => {
    let AccordionArray = faq.map(category => {
        return {
          title: category.title,
          content: (
            <>
            {category.content ? category.description : <h5>{category.description}</h5>}
            </>
          )
        };
      });


      return (
        <GridContainer className={classes.containerDisplay}>
          
            <GridItem
                md={11}
                sm={11}
                xs={11}
                className={classNames(classes.mrAuto, classes.mlAuto, classes.infoArea,classes.handleMarginRight)}
            >
                {/* <GridItem  style={{ paddingLeft:'0px'}}>
                    <h2 className={classes.h2hTitle}>Health Insurance for Parents - Listing Page</h2>
                </GridItem> */}
                <GridItem  style={{ paddingLeft:'0px'}}>
                    <h2 className={classes.h2hTitle}>Health Insurance Plans for Parents</h2>
                </GridItem>
                <h5>Graceful aging is a key guide for a healthy mind, body and soul. An elderly individual is more likely to face health complications. The WHO states that people in older age may face cataracts, back, neck pain and osteoarthritis, diabetes, depression and dementia. Gift your parents with the best health insurance plan and secure savings despite any medical complications.</h5>
                <h2 className={classes.h2hTitle}>Why do parents need Health Insurance policies?</h2>
                <h5>People who are nearing their 60’s have a high possibility of suffering from certain medical ailments. With the rising healthcare costs today, getting health insurance for parents is highly necessary and should be prioritised. A Health Insurance plan can help your parents to be prepared for any medical contingencies, ensuring timely care and quality treatment. The following key points make you understand why your parents must get a health insurance policy right away:</h5>
                <ol>
                    <li>
                        <h5><b>Financial independency</b></h5>
                        <h5>During a medical emergency, you would want to cover your bills on your own, not rely on your family members. You can do this with the help of health insurance. Health insurance is a financial risk management tool where risk is transferred to a pool of people. In this way, you won&apos;t have to depend on anyone else to pay your medical bills.</h5>
                    </li>
                    <li>
                        <h5><b>Keep your savings intact</b></h5>
                        <h5>A comfortable retirement requires years of savings. The decades of accumulated wealth can be depleted in just a single hospitalisation. If you want to prevent this from happening to you, all you need to do is invest in a senior citizen health insurance plan.</h5>
                    </li>
                    <li>
                        <h5><b>It compensates a lack of income</b></h5>
                        <h5>Consider how difficult it would be to cover medical bills through your pensions. With medical insurance for senior citizens on your side, you won&apos;t have to worry about anything like that. In case of a medical emergency, the policy will help you to minimise your out-of-pocket expenses.</h5>
                    </li>
                    <li>
                        <h5><b>Your plans will stay on track</b></h5>
                        <h5>A retirement plan is a dream of an individual through his years of savings. You could embark on a world tour or start a small business you&apos;ve always wanted to. Senior health insurance helps to make your dreams come true. It ensures you are completely covered in the event of a medical emergency.</h5>
                    </li>
                </ol>
                <h2 className={classes.h2hTitle}>How to choose the right health insurance for your parents?</h2>
                <h5>With an increase in age, health often takes a back seat. Also, with time, medical inflation keeps your savings at risk. In this crisis, having the right and the best health insurance plan for your parents is always appreciated. You can also get peace of mind at your elderly age.
                Thus, the following list consists of some useful and effective tips which will help you choose the right health insurance plan for your parents.</h5>
                <ol>
                    <li>
                        <h5><b>Opt For Adequate Coverage</b></h5>
                        <h5>There&apos;s no limit for cover but according to your financial situation, you should see which company provides you the maximum coverage that should be evaluated in order to the premium paid by you. Just check before opting for a health insurance policy for your parents for the best value.</h5>
                    </li>
                    <li>
                        <h5><b>Flexibility</b></h5>
                        <h5>There are varieties of policies and each of these offers different flexibilities. Always go for this kind of policy that gives you substantial flexibility in terms of coverage, tenure, add-ons, the sum insured, etc.</h5>
                    </li>
                    <li>
                        <h5><b>Co-Payments</b></h5>
                        <h5>It is always better to opt for the lowest co-payment structure. Co-pays make your policy economical and affordable.</h5>
                    </li>
                    <li>
                        <h5><b>Look for Pre-existing Illness Coverage</b></h5>
                        <h5>Pre-existing diseases have to be covered with a minimal period. For example - 1 year or 6 months. Ensure if the policy covers your pre-existing diseases and their complications. Some policies do not offer so, choose the given one.</h5>
                    </li>
                    <li>
                        <h5><b>Age for Renewal</b></h5>
                        <h5>This depends on the policy and the customer, but there are such companies that offer &apos;Lifelong renewal&apos;. Choose an insurer with the given options.</h5>
                    </li>
                    <li>
                        <h5><b>Claim Process</b></h5>
                        <h5>Make sure your policy has the simplest claim procedures to follow. Before wasting your time in the claim process, ensure yourself in advance about the process, or else certain difficulties have to be faced</h5>
                    </li>
                </ol>
                <h2 className={classes.h2hTitle}>Recommended Parents’ Health Insurance Plan</h2>
                <h5>Health Insurance cover for Parents can be classified based on the age - Less than 60 years | More than 60 years. Some of the recommended health insurance policies for parents at Star Health are as follows:</h5>
                <ul>
                    <li>   
                        <h3><b>Senior Citizens Red Carpet Health Insurance Policy</b></h3>
                        <h5>The Star’s Senior Citizens Red Carpet Health Insurance Policy offers affordable health insurance coverage for seniors aged 60 to 75. This policy offers a lifelong renewal option, covering a range of daycare procedures and treatments, pre-existing diseases, modern treatments and major medical interventions that may be necessary for the elderly.</h5> 
                        <h5>Usually, health insurance premiums are increased as age advances. However, this policy offers a constant premium irrespective of age. Give your parents the support and love they have bestowed upon you. Make sure your parents receive the right protection and care during their venerable ages by giving them a Senior Citizens Red Carpet Health Insurance Policy and avail tax exemption under section 80D.</h5>
                        <h5><b>What is covered under the Senior Citizens Red Carpet Health Insurance Policy?</b></h5>
                        <h5>Medical expenses are spiking up for elderly people. Insuring with a senior citizen health insurance policy, keep the finances intact and provides timely treatment without any hassle. The major coverage offered under this policy are as follows:</h5>
                        <ul>
                            <li>In-patient hospitalisation charges like ICU expenses, nursing expenses, surgeon’s fees, specialist fees, cost of blood, oxygen, cost of drugs, diagnosis, etc. are covered</li>
                            <li>Pre and post hospitalisation expenses</li>
                            <li>Emergency transportation charges (ambulance)</li>
                            <li>Out-patient consultation at network hospitals</li>
                            <li>Treatment at network/non-network hospitals across India</li>
                            <li>Domiciliary treatment is covered</li>
                            <li>Coverage for modern treatments</li>
                            <li>No medical screening required</li>
                            <li>Coverage for pre-existing diseases</li>
                            <li>Coverage for annual health checkups for every claim-free year</li>
                            <li>In-house claim settlement</li>
                        </ul>
                        <h5><b>What is not covered</b></h5>
                        <h5>The following treatments/illnesses are not covered under the Senior Citizens Red Carpet Health Insurance Policy:</h5>
                        <ol type="I">
                            <li>Treatment outside India</li>
                            <li>Circumcision, sex-change surgery, cosmetic surgery & plastic surgery</li>
                            <li>Refractive error correction less than 7.5 dioptres, hearing impairment correction, dental implants.</li>
                            <li>Substance abuse, self-inflicted injuries, STD’s</li>
                            <li>Hazardous sports, war, terrorism, civil war or breach of law</li>
                            <li>Any kind of service charge, surcharge, admission fees, registration fees, ID card levied by the hospital.</li>
                        </ol>
                        <h5><b>Waiting periods</b></h5>
                        <h5>A 30-day waiting period is applicable for all treatments except in case of accidents.</h5> 
                        <h5>The policy will cover pre-existing diseases after a waiting period of 12 months from the policy’s date of inception.</h5>
                        <h5>Specific diseases mentioned in the policy document will be covered after a waiting period of 24 months from the inception date of the policy. </h5>
                        <h5><b>Tax Benefits</b></h5>
                        <h5>One can avail tax benefit on the premium amount under Section 80D of the Income Tax Act 1961 to an extent of Rs. 50000.</h5>
                        <h5><b>Eligibility criteria</b></h5>
                        <h5>By offering extensive coverage with an increased entry cap of 60-75 years, Senior Citizens Red Carpet Health Insurance Policy is one of the most recommended health care policies for elderly citizens. It was also one of the first insurance policies introduced in India, designed to meet the specific health care needs of seniors. The eligibility criteria are elaborated as follows:</h5>
                        <ul>
                            <li>Entry Age: 60 - 75 years</li>
                            <li>Renewability: Lifelong</li>
                            <li>Sum Assured Range: Rs.1 lakh - Rs. 25 lakhs</li>
                            <li>Medical Examination Requirement: No pre-insurance medical screening is required before granting the policy.</li>
                        </ul>
                        <h5><b>How co-payment works</b></h5>
                        <h5>The Senior Citizens Red Carpet plan is subjected to co-pay as follows:</h5>
                        <Paper className={classes.root}>
                            <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                                
                                <TableBody> 
                                <TableRow>
                                        <TableCell  
                                        rowSpan={1}
                                        align="center" className={classes.tableCellLeftcustomWidth}>
                                        Co-pay for all sum insured
                                        </TableCell>
                                        <TableCell align="center" 
                                        colSpan={1}
                                        className={classes.tableCellLeftcustomWidth}>
                                            30% for admissible claim
                                        </TableCell>
                                </TableRow> 
                                </TableBody>
                            </Table>
                        </Paper>
                        <h5>For example:</h5>
                        <ul>
                            <li><b>For all sum insured -</b> If the admissible claim is 1 lakh and it is a <b>PED/non-ped claim</b>, then 30000 (30%) has to be borne by the insured. The remaining 70000 (70%) will be paid by the insurer.</li>
                        </ul>
                        <h5>
                        <Button
                            variant='contained'
                            color='primary'
                            className={`${classes.button}`}
                            onClick={()=>{
                                browserWindow.location.href="/health-insurance-plan-for-senior-citizens"
                            }}>
                        Know more
                            </Button>
                        </h5>
                    </li>
                    <li>
                        <h3><b>Star Comprehensive Insurance Policy</b></h3>
                        <h5>The Star Comprehensive Insurance Policy offers holistic coverage and acts as a financial safety net in the event of medical emergencies. This policy covers you and your family from 18 to 65 years of age and comes with a lifetime renewal option. You can select a sum insured between 5 lakhs and 1 crore depending on your needs, where you can pay the premiums in installments.</h5>
                        <h5><b>What is covered under the Star Comprehensive Insurance Policy?</b></h5>
                        <h5>Coverage under Star Comprehensive Insurance Policy includes:</h5>
                        <ul>
                            <li>In-patient hospitalisation expenses</li>
                            <li>Pre-and post-hospitalisation expenses</li>
                            <li>Daycare procedures/treatments</li>
                            <li>Domiciliary hospitalisation</li>
                            <li>Ambulance expenses</li>
                            <li>AYUSH treatments</li>
                            <li>Second medical opinion</li>
                            <li>Delivery and new-born cover</li>
                            <li>Organ donor expenses</li>
                            <li>Bariatric surgery</li>
                            <li>Personal accident cover – lump-sum benefit for death & permanent total disablement</li>
                            <li>Out-patient medical consultation</li>
                            <li>Out-patient Dental and Ophthalmic treatments</li>
                            <li>Hospital Cash Benefit</li>
                        </ul>
                        <h5><b>What is not covered</b></h5>
                        <h5>The following is a partial listing of policy exclusions. A detailed list of all exclusions is included in the policy document.</h5>
                        <ul>
                            <li>Pre-existing medical conditions until the completion of the waiting period</li>
                            <li>Any hospitalisation with the primary goal of obtaining diagnostic information</li>
                            <li>Treatment outside of India</li>
                            <li>Circumcision, sex-change surgery, cosmetic surgery, and plastic surgery</li>
                            <li>Refractive error repair correction less than 7.5 dioptres, hearing impairment correction, corrective and aesthetic dental surgery</li>
                            <li>Hazardous or adventure sports-related injuries</li>
                            <li>Unproven treatments</li>
                            <li>Venereal diseases and STDs (other than HIV)</li>
                            <li>Nuclear weapon and war-related perils</li>
                            <li>Intentional self-injury</li>
                        </ul>
                        <h5><b>Waiting periods</b></h5>
                        <h5>A 30-day waiting period is applicable for all treatments except in case of accidents.</h5> 
                        <h5>Pre-existing diseases are covered after a waiting period of 12 months from the date of inception of the policy.</h5>
                        <h5>Specific diseases have a waiting period of 24 months from the policy’s inception date.</h5>
                        <h5><b>Tax Benefits</b></h5>
                        <h5>One can avail tax benefit on the premium amount under Section 80D of the Income Tax Act 1961.</h5>
                        <h5><b>Eligibility Criteria</b></h5>
                        <h5>The Star Comprehensive Insurance Policy can be purchased by individuals between the age of 18 years and 65 years, beyond the age of 65 you have a lifelong renewability option. The comprehensive family floater plan allows its holder to cover their family, including two adults and up to three dependent children between the ages of 3 months and 25 years.</h5>
                        <h5>
                        <Button
                            variant='contained'
                            color='primary'
                            className={`${classes.button}`}
                            onClick={()=>{
                                browserWindow.location.href="/comprehensive-health-insurance-plan"
                            }}>
                        Know more
                            </Button>
                        </h5>
                    </li>
                    <li>
                        <h3><b>Family Health Optima Insurance Plan</b></h3>
                        <h5>Economically supporting a family can be challenging. Therefore, you can opt for a super saver policy that will reduce your expenses while still providing coverage for the entire family. The Family Health Optima (FHO) health insurance plan is appropriately priced so that you can take care of the health issues of every member of your family, even the youngest. If you are a young parent, you can also insure your newborn from the 16th day of birth with in-hospitalisation coverage. FHO brings 3 times automatic restoration of the sum insured by 100% for every complete exhaustion.</h5>
                        <h5>With this plan, you can enjoy a range of benefits such as Automatic Restoration at 300% of your sum insured (100% each time), repatriation of mortal remains, compassionate travel, emergency domestic medical evacuation, organ donor expenses, recharge benefit, additional Sum Insured for Road Traffic Accident (RTA) and assisted reproduction treatment.</h5>
                        <h5><b>What is covered under Family Health Optima Insurance Plan?</b></h5>
                        <ul>
                            <li>In-patient hospitalisation expenses</li>
                            <li>Pre and post-hospitalisation expenses</li>
                            <li>Daycare procedures/treatments</li>
                            <li>Annual health checkup for every claim free year</li>
                            <li>Domiciliary hospitalisation</li>
                            <li>Ambulance expenses</li>
                            <li>AYUSH treatments</li>
                            <li>Second medical opinion</li>
                            <li>New-born baby cover</li>
                            <li>Organ donor expenses</li>
                            <li>Assisted reproduction treatment</li>
                        </ul>
                        <h5><b>What is not covered</b></h5>
                        <ul>
                            <li>Injury or ailment caused due to war-like situations, invasion of enemy etc.</li>
                            <li>Expenses arising due to self-inflicted injury.</li>
                            <li>Dental treatments, unless required due to accidental injuries</li>
                            <li>Vaccinations except for post-bite and other medical treatment</li>
                        </ul>
                        <h5><b>Waiting periods</b></h5>
                        <h5>A 30-day waiting period is applicable for all treatments except in case of accidents.</h5> 
                        <h5>Pre-existing diseases can be covered after a waiting period of 48 months from the policy’s date of inception.</h5>
                        <h5>Specific diseases can be covered after a waiting period of 24 months from the policy’s inception date.</h5>
                        <h5><b>Tax Benefits</b></h5>
                        <h5>One can avail tax benefit on the premium amount under Section 80D of the Income Tax Act 1961.</h5>
                        <h5><b>Eligibility Criteria</b></h5>
                        <h5>Any person aged between 18 years and 65 years, residing in India, can take this insurance. Beyond 65 years, you can benefit with a lifelong renewability option. Child from 16th day of age can be covered as part of the family. 
                        <br/>This policy is on floater basis. Thus a family of Proposer, spouse, dependent children from 16 days up to 25 years, dependent parents and dependent parents in law can be covered.</h5>
                        <h5>
                        <Button
                            variant='contained'
                            color='primary'
                            className={`${classes.button}`}
                            onClick={()=>{
                                browserWindow.location.href="/health-insurance-plans-for-family"
                            }}>
                        Know more
                            </Button>
                        </h5>
                    </li>
                    <li>
                        <h3><b>Young Star Insurance Policy</b></h3>
                        <h5>Youngsters are the backbone of our country’s economic development. Hence, it is important to take good care of your youthful years. The primary motive of Young Star Insurance Policy is to provide a healthy lifestyle for young individuals under the age of 40 and their families. The policy is available in two variants - Silver and Gold, in which you can choose according to your requirements.</h5> 
                        <h5>This policy offers a range of benefits including incentive-led wellness programs, discount on renewals, the least waiting periods, coverage for pre and post-hospitalisation expenses, cumulative bonus, hospital cash benefits, annual check-ups, automatic restoration of the sum insured, and additional coverage for road traffic accidents.</h5>
                        <h5><b>What is covered under the Young Star Insurance Policy?</b></h5>
                        <ul>
                            <li>In-patient hospitalisation</li>
                            <li>Pre and post-hospitalisation expenses</li>
                            <li>Day care treatments</li>
                            <li>Emergency road ambulance</li>
                            <li>Annual health check ups</li>
                            <li>Mid-term inclusion of spouse/newborn baby</li>
                            <li>Delivery Expenses (only under Gold plan)</li>
                            <li>Hospital Cash Benefit (only under Gold plan)</li>
                        </ul>
                        <h5><b>What is not covered?</b></h5>
                        <ul>
                            <li>Rest cure, rehabilitation and respite care</li>
                            <li>Obesity/weight control</li>
                            <li>Circumcision, sex-change surgery, cosmetic surgery & plastic surgery</li>
                            <li>Hazardous or adventure sports</li>
                            <li>Refractive error</li>
                            <li>Dental surgeries unless its accidental</li>
                            <li>Domiciliary treatment, treatment outside India</li>
                            <li>Pregnancy (except under Gold Plan), Infertility, Congenital external disease/defects</li>
                            <li>Substance abuse, Intentional self-injury, war, terrorism, civil war or breach of law</li>
                        </ul>
                        <h5><b>Waiting periods</b></h5>
                        <h5>A 30-day waiting period is applicable for all treatments except in case of accidents.</h5> 
                        <h5>Pre-existing diseases can be covered after a waiting period of 12 months from the policy’s date of inception.</h5>
                        <h5>Specific diseases can also be covered after a waiting period of 12 months from the policy’s inception date.</h5>
                        <h5><b>Tax Benefits</b></h5>
                        <h5>One can avail tax benefit on the premium amount under Section 80D of the Income Tax Act 1961.</h5>
                        <h5><b>Eligibility Criteria</b></h5>
                        <h5>People between 18 years and 40 years of age at the time of entry can take this insurance. Dependent children can be covered from 91 days and up to 25 years of age.
                        <br/>This policy is available both on Individual basis and on Family Floater basis. Family for the purpose of this policy means self, spouse and dependent children not exceeding 3.</h5>
                        <h5><b>Moratorium period</b></h5>
                        <h5>After completion of eight continuous years under the policy no look back to be applied. This period of eight years is called as moratorium period. The moratorium would be applicable for the sum insured of the first policy and subsequent completion of 8 continuous years would be applicable from the date of enhancement of the sum insured only on the enhanced limits. After the expiry of the Moratorium Period, no health insurance claim shall be contestable except for proven fraud and permanent exclusions specified in the policy document. The policies would however be subject to all limits, sub-limits, co-payments, deductibles as per the policy document.</h5>
                        <h5>
                        <Button
                            variant='contained'
                            color='primary'
                            className={`${classes.button}`}
                            onClick={()=>{
                                browserWindow.location.href="/young-star-insurance"
                            }}>
                        Know more
                            </Button>
                        </h5>
                    </li>
                    
                </ul>
                <GridItem  style={{ paddingLeft:'0px'}}>
                    <h2 className={classes.h2hTitle}>Frequently Asked Questions</h2>
                </GridItem>
                <div className={classes.section}>
                    <div className={classes.container}>
                        <GridContainer
                        className={`${classes.container} ${classes.textCenter}`}
                        >
                        <GridItem
                            md={12}
                            style={{ paddingLeft:'0px'}}
                            className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                        ></GridItem>
                        <GridItem
                        style={{ paddingLeft:'0px'}}
                            className={`${classes.mrAuto} ${classes.textCenter}`}
                            xs={12}
                            sm={12}
                            md={12}
                        >
                            <Accordion active={0} collapses={AccordionArray} />
                        </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </GridItem>
            <Helmet>
                <title>
                Best Health Insurance Plans for Parents - Buy Mediclaim Policy for Parents in India | Star Health
                </title>
            </Helmet>
            {process.env.ENABLE_HELMET == `true` && (
                <Helmet>
                    <script type="application/ld+json">
                        {`
                        {
                            "@context":"https://schema.org",
                            "@type":"FAQPage",
                            "mainEntity":[
                                {
                                  "@type":"Question",
                                  "name":"How do I get this policy?",
                                  "acceptedAnswer":{
                                      "@type":"Answer",
                                      "text":"To buy this policy, visit www.starhealth.in or visit your nearest Star Health branch. You can also call the toll-free number 1800 425 2255 / 1800 102 4477 for more details on the plan."
                                  }
                                },
                                {
                                  "@type":"Question",
                                  "name":"Why should I buy a policy for my parents?",
                                  "acceptedAnswer":{
                                      "@type":"Answer",
                                      "text":"Having health insurance for parents is highly essential and should be prioritised. Despite the rising health care costs in India, a health insurance plan can help your parents to be prepared for any medical contingencies, ensuring timely care and quality treatment."
                                  }
                                },
                                {
                                  "@type":"Question",
                                  "name":"Do health insurance policies need a pre-insurance medical screening?",
                                  "acceptedAnswer":{
                                      "@type":"Answer",
                                      "text":"Pre-insurance medical screening requirement depends on what policy you choose."
                                  }
                                },
                                {
                                  "@type":"Question",
                                  "name":"Can I cover my parents in an existing health insurance policy?",
                                  "acceptedAnswer":{
                                      "@type":"Answer",
                                      "text":"Yes. Having multiple policies is always an advantage when the medical bill exceeds the sum insured limit or when your sum insured is exhausted. Such plans serve as indemnity-based health insurance plans."
                                  }
                                },
                                {
                                  "@type":"Question",
                                  "name":"What is the age limit of Senior Citizens Red Carpet Health Insurance Policy?",
                                  "acceptedAnswer":{
                                      "@type":"Answer",
                                      "text":"Senior citizens aged between 60 - 75 years can take this insurance."
                                  }
                                },
                                {
                                  "@type":"Question",
                                  "name":"How do I choose a health insurance with the best coverage?",
                                  "acceptedAnswer":{
                                      "@type":"Answer",
                                      "text":"Choosing a health insurance depends on an individual needs. However, make sure to choose a health insurance policy that has a comprehensive coverage. A comprehensive cover includes coverage for alternate medicines, allopathic treatments, modern treatments, non-payables, in patient/out-patient expenses, annual health checkups and many more."
                                  }
                                },
                                {
                                  "@type":"Question",
                                  "name":"Can an 80-year-old get health insurance?",
                                  "acceptedAnswer":{
                                      "@type":"Answer",
                                      "text":"The Senior Citizens Red Carpet health insurance plan enrolls people between ages 60 - 75 years as of now. Further, renewal is possible even after 75 till they are alive. We are likely to introduce many more policies to suit your requirements. Stay tuned with us."
                                  }
                                },
                                {
                                  "@type":"Question",
                                  "name":"Will my health insurance cover my medical expenses if I get admitted in a non-network hospital?",
                                  "acceptedAnswer":{
                                      "@type":"Answer",
                                      "text":"Yes, your health insurance policy reimburses the treatment expenses incurred by you at a non-network hospital, provided the claim is admissible as per the conditions mentioned in the policy."
                                  }
                                },
                                {
                                  "@type":"Question",
                                  "name":"Is it mandatory to undergo pre-insurance medical screening before purchasing a health insurance policy?",
                                  "acceptedAnswer":{
                                      "@type":"Answer",
                                      "text":"Pre-insurance medical screening helps with the declaration of any pre-existing disease and its severity. Pre-existing diseases plays a major role in health insurance policy. It is compulsory to disclose your health condition related to the pre-existing disease (PED), before purchasing a health insurance policy. A pre-insurance medical screening would be called for by the medical underwriting team."
                                  }
                                }
                            ]
                          }
                        `}</script>
                <meta
                name="title"
                content="Best Health Insurance Plans for Parents - Buy Mediclaim Policy for Parents in India | Star Health"
                />
                <meta
                name="description"
                content="Health insurance Cover is most essential for the elderly to ensure quality treatment, financial security, tax benefits , to beat medical inflation, reduce out-of pocket expenses , and above all peace of mind for all medical exigencies."
                />
                </Helmet>
            )}
        </GridContainer>
  );
};
ParentsPageSEO.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(ParentsPageSEO);